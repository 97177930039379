import { useRouter } from 'next/router';
import ContentLoader from 'react-content-loader';

const ProductCardLoader = (props: any) => {
  const { locale } = useRouter();
  return (
    <ContentLoader
      speed={2}
      width={240}
      height={360}
      rtl={locale === 'ar'}
      viewBox="0 0 240 360"
      backgroundColor="#ddd"
      foregroundColor="#ededed"
      style={{
        border: '1px solid #ddd',
      }}
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="240" height="152" />
      <rect x="9" y="163" rx="0" ry="0" width="117" height="34" />
      <rect x="11" y="210" rx="0" ry="0" width="97" height="4" />
      <rect x="12" y="220" rx="0" ry="0" width="112" height="4" />
      <rect x="13" y="258" rx="0" ry="0" width="160" height="36" />
      <rect x="180" y="258" rx="0" ry="0" width="38" height="36" />
    </ContentLoader>
  );
};
export default ProductCardLoader;
