import { Spinner } from '@Components/common/Spinner';
import {
  AddToCard,
  AddToCartInner,
  Badge,
  Brand,
  Card,
  CardAction,
  CardContent,
  CardImage,
  CarName,
  HorizontalDisplay,
  Info,
  Like,
  Price,
  QuantityBtn,
  QuantityWrapper,
  Title,
} from '@Components/ProductCard/ProductCard.styled';
import { LOGIN_REDIRECT } from '@Constants/index';
import { useAuth } from '@Contexts/AuthContext/Auth.context';
import usePrice from '@Hooks/usePrice';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Cart from 'public/cart.svg';
import HeartFilled from 'public/heart-filled.svg';
import Heart from 'public/heart.svg';
import Logo from 'public/logo.svg';
import LogoMobli from 'public/logoMobli.jpeg';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookie } from 'react-use';
import { Dispatch, RootState } from 'store';
import { Product } from 'types/Products';

interface Props {
  data: Product;
}

export default function ProductCard({ data }: Props) {
  const { t } = useTranslation('common');
  const { push, asPath, pathname } = useRouter();
  const { userData } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { thePrice: price } = usePrice(data.price);
  const { thePrice: specialPrice } = usePrice(data.special_price || 0);
  const { data: cartData } = useSelector((state: RootState) => state.cart);
  const { data: wishListData } = useSelector((state: RootState) => state.profileWishlist);
  const { cart: dispatchCart } = useDispatch<Dispatch>();
  const { profileWishlist: dispatchWishlist } = useDispatch<Dispatch>();
  const [, updateCookieRedirect] = useCookie(LOGIN_REDIRECT);

  const productInCart = useMemo(
    () => cartData?.items.find((pr) => pr.product.id === data.id),
    [cartData?.items, data.id]
  );
  const productInWishlist = useMemo(() => wishListData?.find((pr) => pr.id === data.id), [data.id, wishListData]);

  const handleToggleWishlist = useCallback(
    (inWishlist?: boolean) => {
      if (Boolean(userData)) {
        if (inWishlist) {
          dispatchWishlist.deleteProductFromWishlist(data.id);
        } else {
          dispatchWishlist.addProductToWishlist(data.id);
        }
      } else {
        push('/auth/login').then(() => {
          updateCookieRedirect(
            JSON.stringify({
              asPath,
              pathname,
            })
          );
        });
      }
    },
    [asPath, data.id, dispatchWishlist, pathname, push, updateCookieRedirect, userData]
  );

  const handleAddToCart = useCallback(() => {
    setIsLoading(true);
    dispatchCart
      .addProductToCart({
        product_id: data.id,
        quantity: 1,
      })
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [data, dispatchCart]);

  const updateProductInCart = useCallback(
    (increase) => {
      if (productInCart) {
        setIsLoading(true);
        if (increase) {
          dispatchCart
            .updateProductInCart({
              product_id: productInCart.product.id,
              quantity: productInCart.quantity + 1,
            })
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
        } else {
          if (productInCart.quantity === 1) {
            dispatchCart.deleteProductFromCart(productInCart.product.id).then(() => setIsLoading(false));
          } else {
            dispatchCart
              .updateProductInCart({
                product_id: productInCart.product.id,
                quantity: productInCart.quantity - 1,
              })
              .then(() => setIsLoading(false))
              .catch(() => setIsLoading(false));
          }
        }
      }
    },
    [dispatchCart, productInCart]
  );

  return (
    <Card key={data.id} href={`/products/${data.id}`}>
      {data.special_price && data.price !== data.special_price && (
        <Badge>{Math.ceil(100 - (100 * data.special_price) / data.price)}%</Badge>
      )}
      <CardImage>
        <Image
          src={(data?.images?.find((img) => img.is_main || img.url)?.url as string) || Logo}
          alt={data.name}
          layout="fill"
          priority={true}
          objectFit="contain"
        />
      </CardImage>
      <CardContent>
        <div>
          <Brand>
            <Image
              src={data.brand ? data.brand.image : Logo || Logo}
              alt={data.brand ? data.brand.name : 'yourparts'}
              layout="fill"
              objectFit="contain"
              priority={true}
            />
          </Brand>
          <Title>{data.name}</Title>
          {data?.car_str && data.car_str !== null && <CarName>{data.car_str}</CarName>}
          {data?.category?.is_mobilawy && <Image src={LogoMobli} width={75} height={30} />}
        </div>
        <div>
          {data.special_price && data.price !== data.special_price ? (
            <HorizontalDisplay>
              <Price>{specialPrice}</Price>
              <Info variant="line-throw">{price}</Info>
            </HorizontalDisplay>
          ) : (
            <HorizontalDisplay>
              <Price>{price}</Price>
            </HorizontalDisplay>
          )}
          <CardAction
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Like
              variant="outlined"
              disabled={!Boolean(cartData?.id) || isLoading}
              onClick={() => handleToggleWishlist(Boolean(productInWishlist))}
            >
              <Image
                src={productInWishlist ? HeartFilled : Heart}
                width={20}
                height={17.79}
                alt="like"
                priority={true}
              />
            </Like>
            {productInCart ? (
              <QuantityWrapper>
                <QuantityBtn
                  variant="filled-red"
                  disabled={isLoading || !Boolean(cartData?.id)}
                  onClick={() => updateProductInCart(false)}
                >
                  -
                </QuantityBtn>
                {productInCart.quantity}
                <QuantityBtn
                  variant="filled-red"
                  disabled={isLoading || !Boolean(cartData?.id)}
                  onClick={() => updateProductInCart(true)}
                >
                  +
                </QuantityBtn>
              </QuantityWrapper>
            ) : (
              <AddToCard disabled={isLoading || !Boolean(cartData?.id)} variant="outlined" onClick={handleAddToCart}>
                {isLoading ? (
                  <Spinner size={20} />
                ) : (
                  <AddToCartInner>
                    <Image src={Cart} width={23.25} height={20.97} alt="card" priority={true} />
                    {t('add-to-cart')}
                  </AddToCartInner>
                )}
              </AddToCard>
            )}
          </CardAction>
        </div>
      </CardContent>
    </Card>
  );
}
