import useTranslation from 'next-translate/useTranslation';

export default function usePrice(price: number) {
  const { t, lang } = useTranslation('common');

  const localedPrice = Number(price).toLocaleString(lang === 'ar' ? 'ar-EG' : 'en-US');
  const thePrice = localedPrice + ' ' + t('egp');

  return {
    thePrice,
  };
}
